@import '~@app/scss/variables.scss';

.search-block {
	display: flex;
	align-items: center;
	transform: translateX(86%);
	transition: all 0.5s ease-in-out;
	height: 40px;

	&__input {
		width: 85%;
		transform: translateX(10%);
		transition: all 0.5s ease-in-out;

		.form-control {
			padding-left: 35px;
		}

		.form-control,
		.form-control::placeholder {
			color: $white;
		}
	}

	&__button-search {
		z-index: 3;
		text-decoration: none;

		&:hover {
			text-decoration: none;

			.search-block__button-search__text {
				color: $yellow-color;
			}

			.search-block__button-search__icon {
				path {
					stroke: $yellow-color;
				}
			}
		}

		&__icon {
			path {
				transition: all .5s linear;
			}
		}

		&__text {
			text-decoration: none;
			text-transform: uppercase;
			color: $white;
			transition: all .5s linear;
		}
	}

	&__right-button-search {
		right: 4%;
		text-decoration: none;

		&:hover {
			text-decoration: none;

			.search-block__button-search__text {
				color: $yellow-color;
			}

			.search-block__button-search__icon {
				path {
					stroke: $yellow-color;
				}
			}
		}
	}

	&__button-clean {
		transform: translateX(150px);
		transition: all 0.5s ease-in-out;
	}

	&__button-clean__icon {
		fill: $white;
		transform: rotate(0deg);
		transition: all 0.5s ease-in-out;
	}

	@media screen and (max-width: $width-laptop--md) {
		transform: none;
		max-width: 320px;
		background: #4c698842;
		border-radius: 5px;

		.input-container {
			border: none;

			&.light {
				border: none;
			}
		}

		&__button-search {
			margin-left: 10px;

			&__text {
				display: none;
			}
		}

		&__input {
			width: 100%;
			transform: none;
			border: none;
	
			.form-control {
				padding-left: 10px;
			}

			.form-control,
			.form-control::placeholder {
				color: $white;
			}
		}

		&__button-clean {
			transform: none;
			display: none;
		}
	}

	@media screen and (max-width: $width-mobile--lg) {
		max-width: 200px;

		&__input {
			.form-control {
				padding-left: 0;
				text-overflow: ellipsis;
			}
		}

		&__right-button-search {
			display: none;
		}
	}
}