@import '~@app/scss/variables.scss';

.footer {
	width: 100%;
	height: 408px;
	height: fit-content;
	background-color: $dark-blue-color;
	position: relative;
	flex: none !important;

	&__container {
		height: 100%;
	}

	&__contacts-block {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		column-gap: 5vw;
		padding: 7vw 0;
	}

	&__copyright {
		background-color: #04071A;
		line-height: 200%;
		color: $white;
		padding: 14px 0;
	}

	&__social-block{
		color: $white;

		&__title {
			text-transform: uppercase;
			color: $brand-blue;
			white-space: nowrap;
		}
	}

	&__social-icons {
		display: flex;
		gap: 10px;
		margin-top: 17px;
		margin-bottom: 10px;
	}

	@media screen and (max-width: 930px) {
		&__contacts-block {
			grid-template-columns: none;
		}

		&__social-block {
			margin-top: 61px;

			&__title {
				margin-top: 18px;
			}
		}
	}

	@media screen and (max-width: $width-tablet) {
		&__contacts-block {
			padding: 20vw 0;
		}
	}
}

.drop-list {
	color: $white;

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__title-block {
		padding: 0;
		border: 0;
		margin-bottom: 25px;
		pointer-events: none;
	}

	&__arrow {
		display: none;
	}

	&__link {
		text-decoration: none;
		color: white;
	}

	&__title {
		text-transform: uppercase;
	}

	@media screen and (max-width: $width-tablet--lg) {
		position: relative;

		&__title-block {
			display: flex;
			width: 100%;
			justify-content: space-between;
			border-bottom: 1px solid #FFF;
			padding-bottom: 3px;
			cursor: pointer;
			pointer-events: auto;
		}

		&__arrow {
			fill: $white;
			display: block;
			transition: transform 0.5s ease;
		}

		&__list {
			visibility: hidden;
			transition: visibility .5s, max-height .5s, padding-bottom .5s;
			max-height: 0;
			overflow: hidden;
			padding-bottom: 0;
		}

		&_active {
			.drop-list__list {
				visibility: visible;
				max-height: 200px;
				padding-bottom: 25px;
			}

			.drop-list__arrow {
				transform: rotate(180deg);
			}
		}
	}
}