@import '~@app/scss/variables.scss';

$component-size: 42px;
$icon-size: 16px;

.select-component {
	display: flex;
	flex-direction: row;
	align-items: center;

	min-height: $component-size;
	height: fit-content;
	width: 100%;
	border-bottom: 1px solid $light-grey-color;
	transition: border-bottom-color 0.5s ease;

	&__icon {
		box-sizing: border-box;
		height: $icon-size;
		width: $icon-size;
		display: flex;

		&_active {
			fill: $active-color;
		}
	}

	&__control-block {
		display: flex;
		flex-direction: row;
		justify-content: center;
		flex: 1;
		margin: 10px 0 10px 7px;

		&__placeholder {
			position: absolute;
			left: 0;
			top: 0;
			color: $dark-blue-color;
			transition: all 0.5s ease;
			width: 80%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			&_top {
				color: $dark-grey-color;
				transform: translateY(-24px);
			}
		}

		.sc-core {
			color: $dark-blue-color;

			display: flex;
			flex-direction: row;
			flex: 1;

			&-selector {
				flex: 1;
			}

			&-single {
				.sc-core-selection {
					position: relative;

					&-item {
						position: absolute;
						left: 0;
						transition: color 0.5s ease;
					}
				}
			}

			&-multiple {
				.sc-core {
					&-selection-overflow {
						min-height: 24px;
						height: fit-content;
						display: flex;
						flex-wrap: wrap;
						gap: 10px;

						&-item {
							height: fit-content;
						}
					}

					&-selection {
						&-item {
							height: 100%;
							background: rgba(174, 178, 190, .6);
							border-radius: 5px;
							padding: 0 10px;

							&-content {
								margin-right: 10px;
							}

							&-remove-icon {
								cursor: pointer;
							}
						}

						&-search-mirror {
							display: none;
						}
					}
				}

			}

			&-selection-search-input {
				appearance: none;
				border: none;
				outline: none;
				flex: 1;
				background: transparent;
				font-size: 20px;
				line-height: 24px;
				font-family: 'Fira Sans', sans-serif;
				font-weight: 500;
				color: $dark-blue-color;

				// Remove chrome's cancel button on search input
				&::-webkit-search-cancel-button {
					display: none;
				}
			}

			&-arrow,
			&-clear {
				display: flex;
				flex-direction: column;
				justify-content: center;
				cursor: pointer;
			}

			&-clear {
				order: 1;
			}

			&-arrow {
				order: 2;
				transition: transform 0.5s ease;
			}

			&-open {
				.sc-core-arrow {
					transform: rotate(180deg);
				}
			}

			&-arrow-loading {
				width: 16px;
				height: 24px;
			
				&::after {
					content: '';
					box-sizing: border-box;
					width: 14px;
					height: 14px;
					border-radius: 100%;
					border: 2px solid #999;
					border-top-color: transparent;
					border-bottom-color: transparent;
					transform: none;
					margin-top: 4px;
	
					animation: rcSelectLoading 1s linear infinite;
				}

				svg {
					display: none;
				}

				& ~ span {
					display: none;
				}
			}
		}
	}

	&_focused {
		border-bottom-color: $active-color;

		.select-component {
			&__control-block {
				&__placeholder_top {
					color: $active-color;
				}

				.sc-core {
					&-selection-item {
						color: $light-grey-color;
						z-index: 1;
					}
				}

				.sc-core-multiple {
					.sc-core-selection {
						&-search {
							height: auto;
							width: fit-content;
							display: flex;
						}

						&-item {
							color: $dark-blue-color;
						}
					}
				}

				.sc-core-show-search {
					margin-left: -3px;
				}
			}
		}
	}

	&-container {
		position: relative;

		&__text {
			position: absolute;
			bottom: -2px;
			transform: translateY(100%);
			color: $dark-grey-color;
			transition: color 0.5s ease;
		}
	
		&_error {
			.select-component {
				border-bottom-color: $red-color;

				&__control-block {
					&__placeholder {
						color: $red-color;
					}
				}

				&__icon {
					fill: $red-color;
				}
			}

			.select-component-container__text {
				color: $red-color;
			}
		}
	}
}

.select-component__dropdown {
	box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	position: absolute;
	background: #fff;
	z-index: 2;

	&.sc-core-dropdown-hidden {
		display: none;
	}

	.sc-core {
		&-item {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 4px 16px;
			cursor: pointer;

			&-option {
				position: relative;

				&-active {
					background: $light-blue;
				}

				// ------ Disabled ------
				&-disabled {
					color: $light-grey-color;
				}
			}

			&-empty {
				color: $light-grey-color;
				margin: 10px 5px;
				text-align: center;
			}

			&-group {
				color: $light-grey-color;
				font-size: 14px;
				border-bottom: 1px solid $light-grey-color;
			}
		}
	}
}

@keyframes rcSelectLoading {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
