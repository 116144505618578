@import '~@app/scss/variables.scss';

.input-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 30px;
	border-bottom: 1px solid $dark-blue-color;

	svg {
		margin-left: 10px;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:focus {
		transition: background-color 600000s 0s, color 600000s 0s;
  }

	&.light {
		border-bottom: 1px solid $white;
	}
}

.form-control,
.form-control::placeholder {
	color: $dark-blue-color;
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
}

.form-control {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	border: none;
	background-color: transparent;
	margin-left: 7px;

	&:focus-visible {
		outline: none;
	}
}
