@use 'sass:math';
@import '~@app/scss/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');


.air-gate-way {
    & .container {
        margin: 30px auto;

        @media(min-width: 1680px) {
            max-width: 1585px;
        }

        .table-hed {
            h1 {
                padding-left: 16px;
                display: flex;
                align-items: center;
                min-height: 56px;
                background-color: #0978bd;
                color: #fff;
                font-weight: normal;
                font-size: 22px;
																justify-content: center;
																font-style: italic;
            }

            @media(max-width:768px){
                line-height: 30px;
                font-size: 18px;
            }
        }

        .booking-detals {
            & .booking-row {
                
                display: flex;
                padding-left: 15px;
                padding-right: 15px;
                margin: 10px 0  ;
                p {
																	font-size: 12px;
																	
																	color: #15262f;
																	
																
                    span:nth-child(1) {
                        font-weight: 600;
                    }
																				span:nth-child(2){
																					font-style: italic;
																				}
                }
            }
        }
    }


}



.table-section {
    .data-table {
        height: 400px;
        overflow-y: auto;
    }
}

.table-striped {
    .data-table {
        .rdt_Table {
            .rdt_TableBody {
                .rdt_TableRow:nth-child(odd) {
                    background-color: #cde1f2;
                }
            }
        }
    }
}

.table-section {
    .rdt_TableHeader {
        background-color: #8dbde2;
        color: #000;
    }
}

.table-section {
    .rdt_TableHeadRow {
        font-weight: 700;
        font-size: 16px;
    }
}

table {
	font-family: arial, sans-serif;
	border-collapse: collapse;
	width: 100%;
	table-layout: fixed;
}

td, th {
	border: 1px solid #dddddd;
	text-align: center;
	padding: 8px;
	
}

tr:nth-child(even) {
	background-color: #cde1f2;
}

.table-section {
    .rdt_TableBody {
        .rdt_TableCell {
            font-size: 16px;
        }
    }
}

.bookbtn {
    padding: 10px 20px;
    background-color: #d87544;
    border: none;
    color: #fff;
    cursor: pointer;
}
.action_btn{
	order: none;
	background: #FF8604;
	color: #fff !important;
	border-radius: 5px;
	text-transform: uppercase;
	cursor: pointer;
	font-weight: 600;
	font-size: 10px;
	padding: 3px 10px;
	border:none;
	line-height: 19px;
}

// .air-gate-way .table-section{
// 	height: 500px;
// }

.align-left {
	text-align: left;
}
.air_prices{
	font-size: 13px;
	padding: 15px;
	text-align: center;
	color: #1c2945;
	background: #f1f1f1;
}

.scroll-tables table thead tr th{
	font-size: 14px;
	color: #4c7db5;
	font-family: "Open Sans", sans-serif;
	border: none;
}

.scroll-tables table tbody tr td{
	padding: 10px;
	color: #1c2945;
	font-size: 14px;
	font-weight: 700;
	vertical-align: middle;
	font-family: "Open Sans", sans-serif;
	border: none;
}

.air-gate-way .table-section table tr th{
	font-size: 14px;
	color: #4c7db5;
	font-family: "Open Sans", sans-serif;
	border: none;
}

.air-gate-way .table-section table tr td{
	padding: 10px;
	color: #1c2945;
	font-size: 14px;
	font-weight: 700;
	vertical-align: middle;
	font-family: "Open Sans", sans-serif;
	border: none;
}

.headline{
	font-family: 'Libre Baskerville', serif;
    font-size: 20px;
    font-style: italic;
    text-align: center;
    padding: 15px 10px;
    color: #1c2945;
}


.text-block {
	font-size: 13px;
	padding: 15px;
	text-align: center;
	color: #1c2945;
	background: #f1f1f1;
}

.cstm-border{
	border-right: 1px solid #1c2945 ;
	border-left: 1px solid #1c2945 ;
}