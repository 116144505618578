@import '~@app/scss/variables.scss';

$icon-wrapper-color: #0978BD;

.contacts-widget {
	display: block;
	width: 100%;
	position: fixed;
	bottom: 0;
	background: linear-gradient(0deg, #090E2C -63.43%, rgba(9, 14, 44, 0) 155.09%);
	z-index: 3;
	padding: 15px 24px;

	&__container {
		display: flex;
		flex-direction: row !important;
		justify-content: space-between;
		align-items: center;
	}

	a {
		color: white;
		text-decoration: none;
		text-align: center;

		&:hover {
			text-decoration: none;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
		color: white;
	}

	&__icon-wrapper {
		background-color: $icon-wrapper-color;
		border-radius: 50%;
		width: 60px;
		height: 60px;
		position: relative;

		svg {
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(2);
		}
	}

	.email path {
		stroke: $icon-wrapper-color;
	}

	.chat {
		.contacts-widget__icon-wrapper {
			background-color: $icon-wrapper-color;
			cursor: not-allowed;

			svg {
				width: 32px;
				transform: translate(-50%, -50%) scale(1.1);
			}
		}
	}
}

#chat-widget-container {
	@media (max-width: $width-tablet) {
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
}
