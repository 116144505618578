@import '~@app/scss/variables.scss';

.carousel-container {
	display: flex;
	flex-direction: column;
	height: fit-content;
	width: 100%;
	position: relative;
	padding-bottom: 100px;
	background-color: $brand-bright-blue;
	overflow: hidden;

	&__action-button {
		background-color: $brand-bright-orange;
		z-index: 3;

		&:hover {
			box-shadow: 0 6px 25px adjust-color($brand-bright-orange, $alpha: -0.05);
		}

		&:active {
			background: darken($brand-bright-orange, 5%);
			box-shadow: none;
		}
	}

	&__center {
		margin-top: 70px;
		margin-left: 200px;
		z-index: 2;
		display: flex;
		flex-direction: row;
	}

	&__right {
		background-color: $white;

		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 60%;
	}

	&__title-block {
		color: white;
		height: fit-content;
	}

	&__title {
		margin-bottom: 23px;
	}

	.deals-carousel {
		&__container {
			margin-bottom: -20%;
		}

		&-link {
			text-decoration: none;
			color: $dark-blue-color;
		}
	}

	.slider-container {
		margin-left: 30px;
	}

	.carousel__pagination {
		position: absolute;
		left: 12vw;
		bottom: 105px;

		&-text {
			color: $white;
		}
	}

	.carousel__button {
		path {
			stroke: $white;
		}

		&:active,
		&:hover {
			path {
				stroke: $brand-bright-orange;
				opacity: 1;
			}
		}
	}

	.slider-container {
		width: 100%;
	}

	.slick-slide {
		opacity: 0;
		pointer-events: none;
		transition: all .5s linear;

		&.slick-cloned {
			transition: all .5s linear;
			opacity: 0;
			pointer-events: none;
		}

		&.slick-active {
			transition: all .5s linear .4s;
			opacity: 1;
			pointer-events: auto;
		}

		&.slick-active + .slick-slide {
			transition: all .5s linear .3s;
			opacity: 1;
			pointer-events: auto;
		}
	}

	@media screen and (max-width: $width-laptop--md) {
		padding-bottom: 50px;

		&__right {
			display: none;
		}

		&__center {
			margin-top: 20px;
			margin-left: 0;
			flex-direction: column;
			align-items: center;
		}

		&__title-block {
			text-align: center;
		}

		.carousel__pagination {
			position: unset;
		}

		.slider-container {
			margin-left: 0;
			margin-top: 0;
		}

		.slick-slide {
			opacity: 1;

			&.slick-cloned {
				opacity: 1;
			}
		}
	}
}
