@import '~@app/scss/variables.scss';

$component-size: 42px;
$icon-size: 16px;

.text-field-container {
	display: flex;
	flex-direction: column;
	position: relative;

	&__component {
		display: flex;
		flex-direction: row;
		align-items: center;

		min-height: $component-size;
		height: fit-content;
		width: 100%;
		border-bottom: 1px solid $light-grey-color;
		transition: border-bottom-color 0.5s ease;

		&_focused {
			border-bottom-color: $active-color;
		}

		&__icon-wrapper {
			margin-right: 4px;

			&__icon {
				box-sizing: border-box;
				height: $icon-size;
				width: $icon-size;
				transition: fill 0.5s ease;

				&_active {
					fill: $active-color;
				}
			}
		}

		&__input-group {
			position: relative;
			display: flex;
			flex-direction: row;
			align-items: center;
			flex: 1;
			margin: 10px 0 10px;

			&__input {
				flex: 1;
				border: none;
				background: transparent;
				text-overflow: ellipsis;
				max-width: 100%;
				resize: none;

				&:focus,
				&:active {
					outline: none
				}
			}

			&__placeholder {
				position: absolute;
				top: 1px;
				left: 0;
				transition:
					color 0.5s ease,
					top 0.5s ease,
					font-size 0.5s ease;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				color: $dark-blue-color;

				&_active {
					top: -24px;
					color: $dark-grey-color;
				}

				&_focused {
					color: $active-color;
				}
			}

			&__clear-group {
				display: flex;
				position: absolute;
				right: 0;
				cursor: pointer;

				&__icon {
					width: 16px;
					height: 16px;
				}
			}
		}
	}

	&--textarea {
		margin: 0;

		textarea {
			padding: 0 15px 0 0;
			min-height: 27px;
			height: 27px;
		}
	}

	&__text {
		position: absolute;
		bottom: -2px;
		transform: translateY(100%);
		color: $dark-grey-color;
		transition: color 0.5s ease;
	}

	&_error {
		.text-field-container {
			&__component {
				border-bottom-color: $red-color;

				&__icon-wrapper {
					&__icon {
						fill: $red-color;
					}
				}

				&__input-group {
					&__placeholder {
						color: $red-color;
					}
				}
			}

			&__text {
				color: $red-color;
			}
		}
	}
}
