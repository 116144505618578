@import '~@app/scss/variables.scss';

.loader-overlay__container {
	position: relative;
}

.loader-overlay {
	position: absolute;
	inset: 0;
	background-color: rgba($dark-blue-color, 0.2);

	display: none;
	align-items: center;
	justify-content: center;

	&.loader-overlay_visible {
		display: flex;
	}
}

.lds-ring {
	position: relative;
	width: 50px;
	height: 50px;
	display: flex;
	justify-content: center;
	margin: 0 auto;
}

.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 34px;
	height: 34px;
	margin: 8px;
	border: 5px solid $light-grey-color;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: $light-grey-color transparent transparent transparent;
}

.lds-ring_small {
	height: 24px;
}

.lds-ring_small div {
	width: 25px;
	height: 25px;
	margin: 0;
	border: 5px solid $blue-color;
	border-color: $blue-color transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
