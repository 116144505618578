// colors
$red-color: #F11158;
$dark-blue-color: #090E2C;
$light-grey-color: #AEB2BE;
$dark-grey-color: #8E93A0;
$blue-color: #041556;
$light-blue: #E0F7FF;
$light-color: #FFFFFE;
$aquamarine-color: #9CB3BB;
$yellow-color: #FDBB11;
$white: #FFF;

// Brand-colors
$brand-bright-orange: #FF8604;
$brand-dark-orange: #D87544;
$brand-bright-blue: #207EB8;
$brand-bright-green: #8EC23D;
$brand-blue: #1f7eb9;

$active-color: $blue-color;

// font-size
$xlarge-font: 84px;
$large-font: 60px;
$medium-font: 36px;
$base-title-font: 20px;
$base-font: 18px;
$sm-font: 16px;
$xs-font: 14px;

// screen width
$width-mobile: 320px;
$width-mobile--lg: 480px; // xs
$width-tablet: 768px; // sm
$width-tablet--lg: 930px; // md
$width-laptop--sm: 1024px; // lg
$width-laptop--md: 1440px; // extra lg
$width-laptop--lg: 1920px;

// layout breakpoints
$breakpoint-xs: $width-mobile--lg;
$breakpoint-sm: $width-tablet;
$breakpoint-md: $width-tablet--lg;
$breakpoint-lg: $width-laptop--sm;
$breakpoint-xl: $width-laptop--md;


// skeleton
$skeleton-background: linear-gradient(90deg,#f2f2f2 25%,#e6e6e6 37%,#f2f2f2 63%);
$skeleton-animation: skeleton-animation-loading 1.4s ease infinite;

@keyframes skeleton-animation-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}