@import '~@app/scss/variables.scss';

$notification-margin: 20px;

.topLeft {
	top: $notification-margin;
	left: $notification-margin;
}

.topRight {
	top: $notification-margin;
	right: $notification-margin;
}

.bottomLeft {
	bottom: $notification-margin;
	left: $notification-margin;
}

.bottomRight {
	bottom: $notification-margin;
	right: $notification-margin;
}

.notification {
	z-index: 100;
	position: fixed;
	height: fit-content;
	border-radius: 5px;
	background: rgb(4 7 25 / 60%);
	color: $white;

	&__container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 15px;
	}

	&__type-icon {
		width: 27px;
		height: 27px;
		margin-right: 8px;
		margin-top: 3px;
	}
}
