@import '~@app/scss/variables.scss';

.details {
	&__container {
		flex-direction: row !important;
    padding-top: 43px !important;
    padding-bottom: 76px !important;
	}

	&__right-block {
		&__text {
			margin-bottom: 10px;
		}
	}

	&__header-block {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: 10px;

		&__image {
			max-width: 298px;
			object-fit: cover;
		}
	}

	&__btn {
		margin-bottom: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__support-btn {
		background: $brand-blue;
		width: 100%;
		margin-bottom: 10px;

		&:hover {
			box-shadow: 0 6px 25px adjust-color($brand-bright-blue, $alpha: -0.05);
		}

		&:active {
			background: darken($brand-bright-blue, 5%);
			box-shadow: none;
		}
	}

	&__icons {
		a {
			margin-right: 5px;
		}

		svg path {
			fill: $blue-color;
		}
	}

	&__bottom-block__title {
		border-bottom: 4px solid #104E8B;
    padding-bottom: 8px;
	}

	&__table {
		margin: 20px 0 40px;
	}

	&__with-action-table {
		background: transparent !important;
	}

	&__warning-style {
		span {
			color: #b61e1e;
			font-size: 24px !important;
		}
	}

	&__terms {
		margin: 40px 0;
	}

	&-carousel {
		width: 100%;
		padding: 0;
		overflow: hidden;

		.slick-arrow {
			display: none !important;
		}

		.slick-slide {
			width: 100%;
		}

		.slick-dots {
			padding: 0;
			display: flex !important;
			flex-direction: row;
			justify-content: flex-end;
			list-style: none;
			margin: 0 0 15px;
			column-gap: 5px;

			.slick-active {
				button::before {
					background: linear-gradient(#00CFFF, #1584bc);
					box-shadow: inset 0 0 1px 1px #087DC0;
				}
			}

			li {
				width: 8px;
				height: 8px;
				cursor: pointer;

				button {
					font-size: 0;
					line-height: 0;
					width: 8px;
					height: 8px;
					color: transparent;
					border: 0;
					outline: none;
					background: transparent;
					position: relative;
					cursor: pointer;

					&:before {
						position: absolute;
						top: 0;
						left: 0;
						content: "";
						border-radius: 50%;
						height: 8px;
						width: 8px;
						background: #f9f8f8;
						box-shadow: inset 0 1px 1px 0 #999;
					}
				}
			}
		}
	}

	@media screen and (max-width: $width-laptop--sm) {
		&__container {
			flex-direction: column-reverse !important;
			padding-bottom: 20px !important;
		}

		&__center-block {
			flex: none !important;
		}

		&__right-block {
			flex-direction: row !important;
			flex: none !important;
			justify-content: space-between;
			margin-top: 30px;

			&__btns {
				display: flex;
				flex-direction: column;
				width: 45%;
			}
		}

		&__carousel-wrapper {
			width: 45%;
		}
	}

	@media screen and (max-width: $width-mobile--lg) {
		&__right-block {
			flex-direction: column !important;
			flex: none !important;
			justify-content: space-between;

			&__btns {
				display: flex;
				flex-direction: column;
				width: 100%;
			}
		}

		&__carousel-wrapper {
			width: 100%;
		}
	}

	@media print {
		&__right-block,
		&__btn {
			display: none;
		}
	}
}

.marquee {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
}
	
.marquee span {
	color:#212121;
	display: inline-block;
	padding-top: 10px;
	padding-left: 100%;
	-webkit-animation: marquee 20s infinite linear;
	animation: marquee 20s infinite linear;

	svg {
		width: 15px;
		height: 16px;
		margin: -7px 5px 0 0;
	}

	&::after,
	&::before {
		content: 'May qualify for a bonus offer. Call for details.';
		background-image: url('~@image/icon/fire.svg');
		background-repeat: no-repeat;
    background-size: 20px 17px;
		height: 20px;
		padding-top: 5px;
    display: inline-block;
	}

	&::after {
		padding-left: 30%;
    background-position: 46% 0;
	}

	&::before {
		padding-left: 23px;
    padding-right: 36%;
	}
}
	
@-webkit-keyframes marquee {
	0% {
		-webkit-transform: translate(0, 0);
	}
	100% {
		-webkit-transform: translate(-100%, 0);
	}
}

@keyframes marquee {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(-100%, 0);
	}
}