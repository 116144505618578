@import '@app/scss/variables.scss';

.tag {
	padding: 3px 6px;
	border-radius: 3px;
	color: #201c3f;
	text-transform: uppercase;
	width: fit-content;

	&_no-styles {
		text-transform: none;
	}
}