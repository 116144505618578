@import '~@app/scss/variables.scss';

$height: 742px;
$header-height: 164px;
$header-top-height: 56px;
$header-bottom-height: 108px;

.burger {
	position: fixed;
	top: $header-top-height;
	left: 0;
	bottom: 0;
	transform: translateX(100%);
	transition: all 0.5s ease-in-out;

	width: 100%;
	box-sizing: border-box;

	background-color: $dark-blue-color;
	opacity: .98;
	z-index: 4;
	display: flex;
	flex-direction: column;

	&__header {
		height: $header-bottom-height;
		flex: 0 0 $header-bottom-height;
		border-bottom: 1px solid $white;
	}

	&__content {
		flex: 1;
		margin-top: 7vw;
		margin-left: 10vw;
		overflow-y: auto;
		overflow-x: hidden;
	}

	&__subscribe-form {
		align-self: center;
		text-align: center;

		#subscribe-form {
			.text-field-container {
				&__component {
					border-bottom: 1px solid $white;

					&__icon-wrapper__icon,
					&__icon-wrapper__icon_active {
						path {
							stroke: $white;
						}
					}

					&__input-group {
						&__input,
						&__placeholder {
							color: $white;
						}

						&__clear-group__icon {
							fill: $white;
						}
					}
				}
			}
		}
	}

	&__link-container {
		margin-bottom: 2vw;
	}

	&__link {
		color: $white;
		text-transform: uppercase;
		text-decoration: none;
		transition: all .5s linear;

		&:hover {
			text-decoration: underline;
			color: $yellow-color;
			cursor: pointer;
		}
	}

	&__mobile-links {
		margin-top: 10vh !important;
	}

	&__mobile-content {
		display: none;
	}

	&__footer {
		height: 87px;
		flex: 0 0 87px;
		width: 100%;
		border-top: 1px solid $white;

		display: flex;
		align-items: center;
    justify-content: center;
	}

	&__footer-container {
		display: flex;
		gap: 20px;
	}

	&._active {
		transform: translateX(0);
	}

	&.search {
		.search-block,
		.search-block__input,
		.search-block__button-clean {
			transform: translateX(0);
		}

		.search-block__button-search {
			position: absolute;
		}

		.search-block__button-clean {
			position: absolute;
			right: 0;
			cursor: pointer;

			&:hover {
				.search-block__button-clean__icon {
					transform: rotate(90deg);
					transition: all 0.5s ease-in-out;
				}
			}
		}
	}

	&__footer-container {
		.footer__social-icon-item {
			svg {
				path {
					transition: all .5s ease;
				}
			}
		
			&:hover {
				svg {
					path {
						fill: $brand-dark-orange;
					}
				}
			}
		}
	}

	@media screen and (max-width: $width-laptop--md) {
		&__mobile-content {
			display: flex;
		}

		&__main-links {
			margin-top: 10vh;
		}

		&__header {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 0 100px;

			.search-block {
				display: flex;
			}
		}

		&__header-anchor {
			width: 108px;
		}
	}

	@media screen and (max-width: $width-tablet) {
		&__content {
			margin: 10vw 0 10vw 5vw;
		}

		&__mobile-content {
			margin-top: 0;
		}

		&__header {
			padding: 0 45px;
			height: 96px;
			flex: 0 0 96px;
		}

		&__header-anchor {
			width: 81px;
		}
	}

	@media screen and (max-width: $width-mobile--lg) {
		&__header {
			height: 80px;
			flex: 0 0 80px;
		}

		&__link-container {
			margin-bottom: 0;
		}
	}
}
