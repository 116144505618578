@use 'sass:math';
@import '~@app/scss/variables.scss';

$header-height: 60px;
$grid-gap: 30px;

.search-card {
	margin: 0 auto;
	padding-bottom: 15px;
	background-color: $light-color;
	border-radius: 5px;
	overflow: hidden;
	box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
	width: 100%;
	color: $dark-blue-color;

	.rc-tabs-ink-bar {
		background: $dark-blue-color;
		height: 4px;
	}

	.rc-tabs-nav-wrap {
		padding-left: 35px;
	}

	.rc-tabs-nav {
		box-sizing: border-box;
		height: $header-height;
		background-color: $light-blue;
		border-bottom: 1px solid $dark-grey-color;
	}

	.rc-tabs-nav-list {
		gap: 4vw;
		text-transform: uppercase;
		color: $dark-grey-color;
	}

	.rc-tabs-tab-active {
		color: $dark-blue-color;
	}

	.rc-tabs-extra-content {
		margin-top: 28px;
		padding-right: 35px;
	}

	.rc-tabs-content-holder {
		box-sizing: border-box;
		background-color: $light-color;
		padding: 30px 35px 30px 35px;
	}

	&__intro {
		text-align: center;
		margin-bottom: 15px;
	}

	button {
		font-size: 16px;
		padding: 10px 33px !important;
	}

	&__covid {
		color: $aquamarine-color;
		text-align: center;

		&_bottom {
			display: none;
		}
	}

	#hotel-search-form {
		display: flex;
		flex-direction: column;
		gap: math.div($grid-gap, 2);
		width: 100%;
	}

	&__select-title {
		color: $aquamarine-color;
		text-align: center;
	}

	@media screen and (max-width: 1693px) {
		.btn-hide-text{
			display: none;
		}
	}

	@media screen and (max-width: $width-laptop--sm) {
		.rc-tabs-nav-wrap {
			padding-left: 20px;
		}

		.rc-tabs-extra-content {
			display: none;
		}

		.rc-tabs-nav-list {
			gap: 1vw;
		}

		.button-container {
			margin-top: 15px;
		}

		&__covid {
			display: none;

			&_bottom {
				color: $aquamarine-color;
				text-align: center;
				display: block;
				margin-bottom: 16px;
			}
		}
	}

	@media screen and (max-width: $width-laptop--md) {
		#cruises-search-form,
		#package-search-form,
		#hotel-search-form {
			.btn-hide-text {
				display: inline-block;
			}
		}
	}

	@media screen and (max-width: $width-tablet--lg) {
		.rc-tabs-content-holder {
			padding: 30px 20px 20px;
		}

		.btn-hide-text {
			display: inline-block;
		}
	}

	@media screen and (max-width: $width-tablet) {
		&__intro {
			margin-bottom: 0;
		}

		.rc-tabs-tab {
			font-size: 16px;
			line-height: 20px;
		}

		.rc-tabs-nav {
			height: 50px;
		}
	}
}

.trust-box {
	margin-bottom: 20px;
}
