@import '~@app/scss/variables.scss';

.search-list {
	min-height: 100%;
	position: relative;

	&__counter-block {
		position: sticky;
		top: 0;
		left: 0;
		z-index: 2;
		background: white;
		padding: 15px;
		box-shadow: 0 4px 25px #0000001a;
		margin: -10px -15px 15px;
		border-radius: 5px;
	}

	&__link {
		text-decoration: none;
		color: $dark-blue-color;

		&:first-of-type {
			margin-right: 45px;
		}
	}

	&__container {
		min-height: 90%;
	}

	&__counter-list {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		flex-direction: row;
		column-gap: 45px;
		row-gap: 15px;

		margin-inline: 0;
		margin-block: 0;
		padding-inline: 0;
		padding-block: 0;

		li {
			list-style-type: none;
		}
	}

	&__dropdown-block {
		margin-left: auto;

		&__content {
			display: flex;
			justify-content: space-between;

			p {
				text-overflow: ellipsis;
			}
		}

		span {
			margin-right: 20px;
		}
	}

	&__dropdown-arrow {
		margin-left: 10px;
		transition: all .5s ease .15s;
	}

	&__dropdown-container {
		cursor: pointer;
		border-bottom: 1px solid $blue-color;
		width: 360px;
		padding-left: 5px;

		&:hover {
			.search-list__dropdown-arrow {
				transform: rotate(180deg);
			}
		}
	}

	&__dropdown-menu {
		border-radius: 5px !important;

		li {
			cursor: pointer;
			padding: 5px 40px 5px 10px !important;
			font-size: 14px;

			&:hover {
				background-color: $light-blue;
			}
		}

		.rc-menu-item-selected {
			position: relative;

			&::after {
				content: "";
				position: absolute;
				top: 10px;
				right: 16px;
				width: 10px;
				height: 5px;
				border-right: 1px solid #3CB8F0;
				border-top: 1px solid #3CB8F0;
				transform: rotate(135deg);
			}
		}
	}

	@media screen and (max-width: $width-laptop--md) {
		&__dropdown-container {
			width: 60%;
		}

		&__dropdown-block {
			width: 40%;

			&__content {
				width: 100%;

				p {
					text-overflow: ellipsis;
					width: 100%;
					overflow: hidden;
					white-space: nowrap;
				}
			}
		}
	}

	@media screen and (max-width: $width-tablet--lg) {
		&__dropdown-block {
			display: none !important;
		}
	}
}
