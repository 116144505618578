.expandable-list__container {
	ul.expandable-list {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		column-gap: 5px;
		row-gap: 2px;

		li.ellipsis {
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}

			&:after {
				content: '';
			}
		}

		li:after {
			content: ';'
		}

		li:last-child:after {
			content: '';
		}

	}
}
