@import '~@app/scss/variables.scss';

#subscribe-form {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.signup-section{
	background-color:rgb(224, 247, 255);
	& .signup-form{
		padding: 30px 0;
		margin: 0 auto ;
	}
}

.subscribe {
	height: 883px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	background-image: url('~@image/subscribe.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	position: relative;
	overflow: hidden;

	&__fog {
		background-image: url('~@image/fog.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 0;
		transition: all 1.5s ease;

		&::after {
			content: url('~@image/fog.png');
			position: absolute;
			bottom: -190px;
			left: 0;
			width: 100%;
			opacity: 0;
			transition: all 1s ease-in-out;
		}
	}

	&.animated {
		.subscribe__fog {
			height: 100%;
			opacity: 1;

			&::after {
				bottom: 100px;
				opacity: .1;
			}
		}
	}
	
	&__container {
		max-width: 663px;
		display: flex;
		align-items: center;
		flex-direction: column;
		margin-bottom: 62px;
		text-align: center;
		z-index: 2;
	}

	&__title {
		text-align: center;
	}

	&__description {
		margin: 16px 0 29px;
		text-align: center;
	}

	&__btn {
		margin-top: 12px;
	}

	&__message-btn {
		padding: 0 !important;
		margin-left: 21px;
		margin-bottom: -2px;

		svg {
			transition: all .5s linear;
		}

		&:hover {
			svg {
				fill: $yellow-color;
			}
		}
	}

	@media screen and (max-width: $width-tablet) {
		height: 626px;
		background-size: auto;
		background-position: 50% bottom;

		&__container {
			max-width: 290px;
		}

		&__description {
			margin: 21px 0 10px;
		}

		&__input,
		&__btn {
			width: 100%;
		}
	}
}

#subscribe-form {
	.text-field-container {
		&__component {
			border-bottom: 1px solid $dark-blue-color;

			&__icon-wrapper__icon_active {
				fill: none;
			}
		}

		&_error {
			.text-field-container__component {
				border-bottom-color: $red-color;

				&__icon-wrapper__icon {
					fill: none;
	
					path {
						stroke: $red-color;
					}
				}
			}
		}
	}
}