@import '~@app/scss/variables.scss';

.why-us {
	width: 100%;
	background-color: $brand-bright-blue;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	color: $white;
	padding-top: 120px;
	padding-bottom: 120px;
	gap: 20px;

	&__title {
		text-align: center;
	}

	&__bullet-container {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		align-items: start;
		justify-items: center;
		column-gap: 100px;
	}

	@media screen and (max-width: $width-tablet--lg) {
		padding-top: 65px;
		padding-bottom: 65px;

		&__bullet-container {
			max-width: 320px;
			grid-template-columns: none;
			align-items: start;
			justify-items: center;
			row-gap: 30px;
		}
	}
}

