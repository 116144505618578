@import '~@app/scss/variables.scss';

.search-bar {
	background-color: $light-blue;
	transition: padding-bottom .5s;

	&__action-btn {
		display: flex;
		justify-content: flex-end;
		padding-bottom: 20px;
		cursor: pointer;
	}

	&__container {
		transition: opacity .2s ease, height .5s ease, padding .5s ease;
		gap: 37px;
		opacity: 0;
		height: 0;
		padding: 0;
		
	}

	&__link-button {
		font-size: 14px !important;
		line-height: 22px !important;
	}

	&__arrow {
		display: block;
		transition: transform 0.5s ease;
	}

	&-active {
		padding-bottom: 41px !important;

		.search-bar {
			&__container {
				opacity: 1;
				height: auto;
				padding: 10px 15px;
			}

			&__arrow {
				transform: rotate(180deg);
			}
		}
	}

	@media screen and (max-width: $width-tablet--lg) {
		&__link-button {
			padding-top: 0 !important;
			padding-bottom: 0 !important;
		}
	}

	@media  screen and (min-width: $width-laptop--md) {
		&__reset {
			padding-left: 0 !important;
			padding-right: 0 !important;

			button {
				text-align: right;
			}
		}

		&__advanced-link {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
	}
}