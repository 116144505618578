@import '~@app/scss/variables.scss';


.documentation .droplist {
	ul.droplist__list {
		padding: 0 0 0 22px;
		list-style: none;

		& > li {
			margin-bottom: 5px;
			position: relative;

			&::before {
				content: url('~@image/icon/right-caret.svg');
				position: absolute;
				left: -23px;
				top: -3px;
				transition: all .5s ease;
			}
		}

		.action {
			&::before {
				transform: rotate(90deg);
			}
		}

		ul {
			list-style: disc inside;
			margin: 0;
			padding: 0;
		}
	}

	&__list {
		&__title {
			cursor: pointer;
			color: $brand-bright-blue;

			&:hover {
				text-decoration: underline;
			}
		}

	}

	&__text-block {
		opacity: 0;
		height: 0;
		pointer-events: none;
		padding: 0 15px 0;
		transition: opacity .5s, height .4s, padding .5s;

		background: #E3F3F8;
		color: black;
		border-top: 5px solid #21c2f8;
		border-bottom-left-radius: 15px;
		border-bottom-right-radius: 15px;
		margin-left: -15px;
	}

	._visible + div {
		height: fit-content;
		opacity: 1;
		margin-top: 15px;
		padding: 15px;
		pointer-events: auto;
	}
}
