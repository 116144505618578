@import '@app/scss/variables.scss';

.slick-slider {
	padding: 0 3vw;

	.slick-track {
		display: flex;
	}

	@media screen and (max-width: 930px) {
		padding: 0 0;
	}
}

.carousel {
	&__pagination {
		display: flex;
		margin-top: 30px;
		justify-content: center;
	}

	&__pagination-text {
		margin: 0 14px;
		color: $dark-blue-color;
	}

	&__button {
		path {
			stroke: $dark-blue-color;
			opacity: .4;
		}

		&:active,
		&:hover {
			path {
				stroke: $brand-bright-orange;
				opacity: 1;
			}
		}
	}
}
