@import '~@app/scss/variables.scss';

.search-list-card {
	background: $white;
	box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
	border-radius: 0 0 5px 5px;
	height: fit-content;
	margin-bottom: 37px !important;

	display: grid;
	grid-template-rows: repeat(2, minmax(74px, auto));
	grid-template-columns: minmax(auto, 363px) 1fr minmax(auto, 295px);
	grid-template-areas:
	'image content-header actions-header'
	'image content actions'
	;

	&_special-grid {
		grid-template-rows: repeat(2, minmax(74px, auto));
		grid-template-columns: 1fr minmax(auto, 295px);
		grid-template-areas:
		'content-header actions-header'
		'content actions'
		;
	}

	&__left-block {
		grid-area: image;

		object-fit: scale-down;
		max-width: 363px;
		width: 100%;
		height: 100%;
		background-color: $light-blue;
	}

	&__header {
		grid-area: content-header;
		color: $dark-blue-color;
		text-transform: uppercase;
		border-bottom: 1px solid $light-grey-color;
		height: fit-content;
		min-height: 74px;

		&__logo {
			object-fit: contain;
			width: 100%;
			min-width: 85px;
		}

		h1,
		h2,
		h3,
		h4 {
			text-wrap: balance;
		}
	}

	&__content {
		grid-area: content;

		padding: 20px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		height: fit-content;

		svg {
			fill: $dark-blue-color;
		}
	}

	&__cruise-line-logo {
		width: 138px;
		max-height: 44px;
		object-fit: contain;
	}

	&__right-block {
		grid-area: actions;

		background-color: $light-blue;
		display: flex;
		flex-direction: column;
		padding: 16px;
	}

	&__brand-logo-block {
		grid-area: actions-header;
		background-color: $light-blue;

		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__brand-logo {
		width: 129px;
		height: 36px;
		object-fit: contain;
	}

	&__actions {
		grid-area: actions;

		display: flex;
		flex-direction: column;
		width: 100%;

		align-items: center;
		justify-content: center;
		gap: 14px;

		margin: auto 0 16px 0;

		> * {
			box-sizing: border-box;
			width: clamp(100px, 75%, 400px);

			display: flex;
			justify-content: center;
			text-align: center;
		}
	}

	&__price-info {
		text-align: center;

		max-width: 75%;
    flex-direction: column;
		margin-bottom: 20px;
	}

	&__btn-block {
		display: flex;
    flex-direction: column;
    justify-content: space-around;
    row-gap: 20px;

		&_auto {
			margin-top: auto;
		}
	}

	&__more-btn {
		border-radius: 4px;
		padding: 16px 24px;
	}

	&__inclusion-modal.modal__container {
		height: 50vh;
	}

	&__hidden-text {
		display: inline;

		cursor: pointer;
		transition: all .5s ease;

		&:hover {
			text-decoration: underline;
		}

		.departure-list {
			&:nth-of-type(2) {
				display: flex;
				flex-wrap: wrap;
				margin-top: 2px;
			}

			li {
				white-space: nowrap;
			}
		}
	}

	&__includes.expandable-list__container ul.expandable-list {
		$gap: 8px;

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: baseline;
		gap: $gap;

		margin: 0;
		padding: 0;

		li {
			display: flex;
			flex-basis: calc(50% - $gap);
			align-items: center;
			text-wrap: balance;

			&::before {
				content: '⦁';
				padding-right: 4px;
			}

			&:after {
				content: '';
			}

			a {
				text-decoration: none;
				color: $brand-bright-blue;
				transition: all .5s ease;

				&:hover {
					color: $brand-dark-orange;
					text-decoration: underline;
				}
			}
		}
	}

	@media screen and (max-width: $width-laptop--md) {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: minmax(74px, auto) 1fr minmax(74px, auto) auto;
		grid-template-areas:
			'image actions-header'
			'image actions'
			'content-header content-header'
			'content content';

		&_special-grid {
			grid-template-areas:
			'actions-header actions-header'
			'actions actions'
			'content-header content-header'
			'content content';
		}

		&__left-block {
			max-width: none;
		}
	}

	@media screen and (max-width: $width-tablet) {
		grid-template-columns: repeat(1, 1fr);
		grid-template-rows: auto minmax(74px, auto) auto minmax(74px, auto) auto;
		grid-template-areas:
			'image'
			'content-header'
			'content'
			'actions-header'
			'actions';

		&_special-grid {
			grid-template-rows: minmax(74px, auto) auto minmax(74px, auto) auto;
			grid-template-areas:
			'content-header'
			'content'
			'actions-header'
			'actions';
		}

		&__content {
			.mobile-margin {
				margin: -15px auto 5px;
			}
		}
	}

	@media screen and (max-width: $width-mobile--lg) {
		&__header {
			flex-direction: column !important;
		}
	}
}
