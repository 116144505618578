@import '~@app/scss/variables.scss';

.cookies {
	display: flex;
	flex-direction: column;

	&__actions {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 10px;
	}

	&__link {
		color: $yellow-color;
		text-decoration: none;
	}

	&__accept {
		font-size: 16px !important;
		line-height: 18px !important;
		padding: 5px 10px !important
	}
}