@use "sass:math";
@import '~@app/scss/variables.scss';

$cruise-line-block-height: 648px;

$row-height: 216px;
$row-height-md: 144px;
$row-height-sm: 72px;

.cruise-line {
	background: $white;
	margin-top: 6vw;
	display: flex;

	&__container {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		width: 100%;
	}

	&__column {
		display: flex;
		flex: 1;
		flex-wrap: wrap;
		align-content: flex-start;
	}

	&__item {
		height: $row-height;
		background-repeat: no-repeat;
		display: flex;
		align-items: center;
		justify-content: center;
		background-position: center;
		background-size: 50%;
		flex: 1 1 50%;
	}

	&__title {
		margin-bottom: 10px;

		&-block {
			align-items: flex-start;
			flex-direction: column;
			margin-left: 10vw;

			button {
				width: fit-content;
			}
		}
	}

	.holland-item {
		max-width: 50%;
	}

	.big-ship-item {
		height: 2 * $row-height;
		width: 100%;
		align-items: flex-start;
		justify-content: flex-start;
	}

	@media screen and (max-width: 1150px) {
		&__title-block {
			margin: 0 5vw;
			justify-content: flex-start;
		}
	}

	@media screen and (max-width: $width-tablet--lg) {
		&__column {
			flex: 1 1 100%;
		}

		&__item {
			background-size: 50%;
			height: $row-height-md;
		}

		&__title-block {
			text-align: left;
			justify-self: start;
			justify-content: flex-end;
			height: 1 * $row-height-md;
			width: fit-content;

			margin: 0 5vw;
			margin-bottom: 19px;
		}

		.big-ship-item {
			height: 2 * $row-height-md;
		}

	}

	@media screen and (max-width: $width-tablet) {
		&__item {
			background-size: 50%;
			height: $row-height-sm;
		}

		&__title-block {
			height: 1.5 * $row-height-sm;
		}

		.big-ship-item {
			background-position: bottom;
			height: 2 * $row-height-sm;
		}
	}

	@media screen and (max-width: $width-mobile--lg) {
		&__title-block {
			height: 2.5 * $row-height-sm;
			max-width: 80%;

			button {
				width: 100%;
			}
		}
	}
}
