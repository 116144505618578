@import '~@app/scss/variables.scss';

.which-one {
	height: 628px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: $light-blue;
	gap: 15px;

	&__container {
		max-width: 772px;
		margin-left: 13vw;
		z-index: 3;
	}

	&__description {
		margin: 42px 0 20px;
	}

	&__image {
		background-repeat: no-repeat;
	}

	@media screen and (max-width: $width-laptop--md) {
		height: fit-content;
		flex-direction: column;
		justify-content: flex-end;
		align-items: start;

		&__container {
			margin: 13vw 5vw 0;
			margin-bottom: 24px;
		}

		&__image {
			width: 100%;
			height: 75vw;
		}

		&__description {
			margin: 20px 0 16px;
		}
	}

	@media screen and (max-width: $width-mobile) {
		&__container {
			max-width: 100%;
		}

		&__btn {
			width: 100%;
		}
	}
}