@import '~@app/scss/variables.scss';

.modal {
	width: 100%;
	height: 100vh;
	z-index: 11;
	position: fixed;
	top: 0;
	left: 0;
	visibility: hidden;
	opacity: 0;
	transition: all .5s linear;
	display: flex;
	justify-content: center;
	align-items: center;

	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(180deg, #090E2C -63.43%, rgba(9, 14, 44, 0) 155.09%);
	}

	&__container {
		position: relative;
		height: fit-content;
		background: $white;
		border-radius: 5px;
	}

	&__btn-close {
		position: absolute;
		top: -15px;
		right: -15px;
		width: 34px;
		height: 34px;
		border-radius: 100%;
		border: 3px solid $white;
		background: $blue-color;
		color: $white;
		cursor: pointer;

		svg {
			width: 20px;
			fill: $white !important;
			margin-left: -2px;
			transition: all 1.5s ease;
			transform: rotate(0deg);
		}

		&:hover {
			svg {
				transform: rotate(180deg);
			}
			
		}
	}
}

.modal.modal-show {
	visibility: visible;
	opacity: 1;
}