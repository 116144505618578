@import '~@app/scss/variables.scss';

.btn {
	border-radius: 4px;
	background: none;
	border: none;
	font-style: normal;
	font-weight: 500;
	text-align: center;
	transition: all .5s linear;

	&:hover {
		cursor: pointer;
	}

	&:disabled {
		cursor: unset;
		pointer-events: none;
	}

	&.uppercase {
		text-transform: uppercase;
	}

	&-red {
		background-color: $brand-dark-orange;
		color: white;
		padding: 16px 33px !important;

		&:hover {
			box-shadow: 0 6px 25px adjust-color($brand-dark-orange, $alpha: -0.05);
		}

		&:active {
			background: darken($brand-bright-orange, 5%);
			box-shadow: none;
		}
	}

	&-line {
		font-style: normal;
		font-weight: 500;
		font-size: $xs-font;
		line-height: 22px;
		color: $dark-grey-color;
		text-decoration: underline;
		padding: 0;

		&:hover {
			color: $yellow-color;
			text-decoration: underline;
		}

		&:active {
			color: #d29a0d;
		}
	}

	&-outline {
		background: transparent;
		border: 1px solid #fff;
		border-radius: 50px;
		color: $white;
		padding: 9px 33px;

		&:hover {
			color: $dark-blue-color;
			background: $white;
			box-shadow: 0px 6px 25px rgba(225, 225, 225, 0.6);
		}

		&:active {
			background: $white;
			box-shadow: none;
		}

		&.dark {
			border: 1px solid $dark-blue-color;
			color: $dark-blue-color;

			&:hover {
				color: $white;
				background: $dark-blue-color;
				box-shadow: 0px 6px 25px rgba(9, 14, 44, 0.6);
			}

			&:active {
				background: #030618;
				box-shadow: none;
			}
		}
	}

	@media screen and (max-width: $width-tablet) {
		line-height: 17px;
		padding: 11px 33px !important;
	}
}

a.btn[href^="tel:"] {
	text-align: start;
	text-decoration: none;
	padding: 0 !important;
}
