@import '~@app/scss/variables.scss';

.checkbox {
	color: $dark-blue-color;

	&__error-text {
		color: $red-color;
		margin-top: 3px;
	}

	label {
		cursor: pointer;
	}

	input {
		width: 16px;
		height: 16px;
		position: relative;
		margin: 0;
		transition: box-shadow 500ms ease;

		&:hover {
			box-shadow: 0 6px 25px rgba(9, 14, 44, 0.6);
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 14px;
			height: 14px;
			border: 1px solid $dark-blue-color;
			border-radius: 3px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 10px 14px;
			background-color: $white;
			transition: all .5s linear;
			cursor: pointer;
		}

		&:checked::before {
			background-color: $dark-blue-color;
			background-image: url('~@image/icon/checkbox-check.svg');
		}

		&:indeterminate::before {
			content: '-';

			display: flex;
			width: 100%;
			height: 100%;
			align-items: center;
			justify-content: center;

			color: $white;
			background-color: $dark-blue-color;
		}

		&:disabled::before {
			background-color: #aeb2be3d;
			border: 1px solid rgb(142 147 160 / 57%);
			box-shadow: none;
		}

		&:checked:disabled {
			appearance: none;
		}

		&:checked:disabled::before {
			background-image: url('~@image/icon/checkbox-check-disable.svg');
		}
	}
}

.radio {
	input::before {
		border-radius: 50%;

		&:checked::before {
			background-color: $dark-blue-color;
			background-image: url('~@image/icon/checkbox-check.svg');
		}
	}
}

.error-checkbox {
	color: $red-color;

	input::before {
		border: 1px solid $red-color;
	}
}

.checkbox-group {
	margin-left: 26px;
	margin-bottom: 12px;
}
