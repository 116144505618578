.bullet-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	&__title {
		margin: 16px 0 18px;
		text-transform: uppercase;
	}

	svg {
		height: 40px;
	}
}