@import '~@app/scss/variables.scss';

$height: 642px;
$header-height: 164px;
$header-top-height: 56px;
$header-bottom-height: 108px;

.site-header {
	width: 100%;
	overflow: hidden;

	color: $white;

	&.is-main-page {
		background-image: url('~@image/header.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		width: 100%;
		height: fit-content;
		min-height: $header-height;
		padding-bottom: 2vw;

		.site-header__bottom-block {
			background: linear-gradient(180deg, #090E2C -63.43%, rgba(9, 14, 44, 0) 155.09%);
		}
	}

	&__top-block {
		box-sizing: border-box;
		height: $header-top-height;
		background-color: #040719;
		padding: 15px 100px;
	}

	&__left-wrap {
		color: $yellow-color;
		text-align: center;

		a {
			display: inline-block;
			margin-left: 20px;
			color: $yellow-color;
			text-decoration: none;
		}
	}

	&__right-wrap {
		flex-direction: row;
		align-items: center;
		display: flex;
		gap: 55px;
		margin-left: auto;
	}

	&__bottom-block {
		background: $dark-blue-color;
		padding: 25px 100px;

		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__menu {
		position: absolute;
		top: 0;
		left: 0;
		width: 742px;
		white-space: nowrap;
		height: 40px;
		overflow: hidden;
	}

	&__title {
		margin: 0 auto;
		margin-top: 4vw;
		text-align: center;
	}

	&__right-block {
		position: relative;
		height: 40px;
		width: 808px;

		display: flex;
		align-items: center;
	}

	&__menu-items {
		opacity: 1;
		transition: all 0.5s ease-in-out;
		gap: 3vw;
		z-index: 2;
	}

	&__menu-items {
		.menu-item__link {
			opacity: 1;
			transition: all 0.5s ease-in-out;
		}
	}

	&__right-block.search {
		.site-header__menu-items {
			z-index: -1;

			.menu-item__link {
				opacity: 0;
				pointer-events: none;
			}
		}

		.search-block,
		.search-block__input,
		.search-block__button-clean {
			transform: translateX(0);
		}

		.search-block__button-search,
		.search-block__right-button-search {
			position: absolute;
		}

		.search-block__button-clean {
			position: absolute;
			right: 15%;
			cursor: pointer;

			&:hover {
				.search-block__button-clean__icon {
					transform: rotate(90deg);
					transition: all 0.5s ease-in-out;
				}
			}
		}
	}

	.burger__header {
		.search-block {
			display: none;
		}
	}

	@media screen and (max-width: $width-laptop--md) {
		.search-block,
		&__menu-items {
			display: none;
		}

		.burger__header {
			.search-block {
				display: flex;
			}
		}

		&__title {
			margin-top: 33px;
		}

	}

	@media screen and (max-width: $width-tablet) {
		&__top-block {
			box-sizing: border-box;
			height: $header-top-height;
			padding: 10px 31px;
		}

		.site-header__top-block.site-header__top-block_hidden {
			display: none;
		}

		&:has(.site-header__top-block_hidden) {
			.burger {
				top: 0;
			}
		}

		&__bottom-block {
			padding: 19px 17px;
		}

		&__title {
			margin-top: 15px;

			b,i {
				font-size: 30px !important;
			}
		}
	}

	@media screen and (max-width: $width-mobile--lg) {
		&__logo {
			width: 193px;
			height: 30px;
		}
	}
}

.menu {
	display: flex;
	justify-content: space-between;
	align-items: center;

	overflow: hidden;

	&-item {
		&__phone {
			color: $brand-bright-orange;
			text-decoration: none;

			img ~ span {
				margin-left: 10px;
			}
		}

		&__request {
			text-decoration: none;
			color: $white;
		}

		&__authorization-block {
			&__item {
				padding-right: 30px;

				&-content {
					color: $white;
					text-decoration: none;
				}

				& ~ & {
					padding-left: 5px;
				}

				&:last-child {
					padding-right: 0;
				}
			}
		}

		&__link {
			color: $white;
			text-transform: uppercase;
			text-decoration: none;

			width: fit-content;
			margin-right: 20px;
		}

		&__search {
			cursor: pointer;
		}

		&__burger {
			cursor: pointer;
			position: absolute;
			right: 0;
			z-index: 3;
			transform: rotate(0);
			transition: all 0.5s ease-in-out;

			&-icon {
				width: 40px;
				height: 15px;
				position: relative;

				&::after,
				&::before {
					content: '';
					width: 40px;
					height: 1px;
					background: white;
					position: absolute;
					transform: rotate(0);
					transition: all 0.5s ease-in-out;
				}

				&::after {
					bottom: 0;
					left: 0;
				}

				&::before {
					top: 0;
					left: 0;
				}
			}

			&.icon_active {
				z-index: 10;

				.menu-item__burger-icon::after {
					transform: rotate(135deg);
					bottom: 8px;
				}

				.menu-item__burger-icon::before {
					transform: rotate(225deg);
					top: 6px;
				}
			}
		}
	}

	@media screen and (max-width: $width-laptop--md) {
		justify-content: center;

		&-item {
			&__request,
			&__authorization-block {
				display: none;
			}
		}
	}

	@media screen and (max-width: $width-tablet) {
		&-item__phone {
			display: none;
		}
	}

	@media screen and (max-width: $width-mobile--lg) {
		&-item__burger {
			padding: 0;
		}
	}
}
