@import '~@app/scss/variables.scss';

.filters {
	border-radius: 0 0 5px 5px;
	background: #fffffe;
	box-shadow: 0 8px 25px rgba(0, 0, 0, 0.08);
	min-height: 475px;
	height: fit-content;
	margin-right: 30px;
	position: sticky;
	top: 0;

	&__header {
		border-radius: 5px 5px 0 0;
		color: $white;
		background: $brand-bright-blue;
		text-transform: uppercase;
	}

	&__checkbox-container {
		gap: 20px;
		margin-top: 20px;
	}

	&__checkbox-group {
		&_parent {
			margin-top: 5px;
		}


		&_child {
			margin: 5px 0 5px 25px;
		}
	}

	.rc-collapse-content {
		overflow-y: auto;
		overflow-x: hidden;
		color: #666;
		background-color: #fff;

		transition: all .5s linear;
	}

	.rc-collapse-content > .rc-collapse-content-box {
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding: 10px 30px;
		max-height: 230px;
		box-sizing: border-box;
	}

	.rc-collapse-content-hidden {
		visibility: hidden;
		max-height: 0;
	}

	.rc-collapse {
		margin-top: 12px;
		padding: 10px 0;
		width: 100%;
	}

	.rc-collapse > .rc-collapse-item > .rc-collapse-header .arrow {
		content: url('~@image/icon/chevron_down.svg');
		height: 15px;
		transition: all .5s linear;
	}

	.rc-collapse > .rc-collapse-item > .rc-collapse-header {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
		align-items: center;
		line-height: 22px;
		padding: 18px 30px;
		cursor: pointer;
		border-bottom: 1px solid $light-grey-color;
		position: relative;

		.rc-collapse-header-text {
			padding-right: 28px;
			text-overflow: ellipsis;
			width: 100%;
			overflow: hidden;
			white-space: nowrap;
			position: relative;
		}
	}

	.rc-collapse > .rc-collapse-item-active > .rc-collapse-header .arrow {
		transform: rotate(180deg);
	}

	.rc-collapse > .rc-collapse-item > .rc-collapse-header .rc-collapse-extra {
		margin: 0 11px 0 0;

		path {
			fill: $dark-blue-color;
		}
	}

	.rc-collapse-expand-icon {
		margin-left: auto;
	}

	.rc-collapse > .rc-collapse-item-disabled > .rc-collapse-header {
		cursor: not-allowed;
		color: #999;
		background-color: #f3f3f3;
	}

	@media screen and (max-width: $width-tablet--lg) {
		display: none !important;
		pointer-events: none;
	}
}

// css-motion styles
.filter-panel-reveal {
	max-height: 230px;

	&::-webkit-scrollbar {
		width: 0;
	}
}

.filter-panel-reveal-enter {
	max-height: 0;

	&::-webkit-scrollbar {
		width: 0;
	}
}

.filter-panel-reveal-enter-active {
	max-height: 230px;
	transition: all .5s;

	&::-webkit-scrollbar {
		width: 0;
	}
}

.filter-panel-reveal-leave {
	max-height: 230px;

	&::-webkit-scrollbar {
		width: 0;
	}
}

.filter-panel-reveal-leave-active {
	max-height: 0;
	transition: all .5s;

	&::-webkit-scrollbar {
		width: 0;
	}
}

.mobile-filters {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fffffe;
	box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.08);
	min-height: 475px;
	margin-right: 30px;
	overflow: hidden;
	overflow-y: scroll;
	z-index: 14; // to block the online chat icon & burger btn

	.filters__header {
		border-radius: 0;
		color: $white;
		background: $brand-bright-blue;
		text-transform: uppercase;
	}

	&__content {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;

		.filters__checkbox-container {
			justify-content: flex-end;
		}
	}

	&__btn-close {
		position: absolute;
		top: 15px;
		right: 23px;
		width: 34px;
		height: 34px;
		border: none;
		background: $brand-bright-blue;
		color: $white;
		cursor: pointer;

		svg {
			width: 30px;
			fill: $white;
			margin-left: -2px;
			transition: all 1.5s ease;
			transform: rotate(0deg);
		}

		&:hover {
			svg {
				transform: rotate(180deg);
			}
		}
	}

	.rc-collapse-content {
		overflow-y: auto;
		overflow-x: hidden;
		color: #666;
		background-color: #fff;

		transition: all .5s linear;
	}

	.rc-collapse-content > .rc-collapse-content-box {
		display: flex;
		flex-direction: column;
		gap: 15px;
		padding: 10px 30px;
		max-height: 230px;
		box-sizing: border-box;
	}

	.rc-collapse-content-hidden {
		visibility: hidden;
		max-height: 0;
	}

	.rc-collapse {
		margin-top: 12px;
		padding: 10px 0;
		width: 100%;
	}

	.rc-collapse > .rc-collapse-item > .rc-collapse-header .arrow {
		content: url('~@image/icon/chevron_down.svg');
		height: 15px;
		transition: all .5s linear;
	}

	.rc-collapse > .rc-collapse-item > .rc-collapse-header {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
		align-items: center;
		line-height: 22px;
		padding: 25px 30px;
		cursor: pointer;
		border-bottom: 1px solid $light-grey-color;
		position: relative;
	}

	.rc-collapse > .rc-collapse-item-active > .rc-collapse-header .arrow {
		transform: rotate(180deg);
	}

	.rc-collapse > .rc-collapse-item > .rc-collapse-header .rc-collapse-extra {
		margin: 0 11px 0 0;

		path {
			fill: $dark-blue-color;
		}
	}

	.rc-collapse-expand-icon {
		position: absolute;
		right: 30px;
	}

	.rc-collapse > .rc-collapse-item-disabled > .rc-collapse-header {
		cursor: not-allowed;
		color: #999;
		background-color: #f3f3f3;
	}
}

.sorter {
	height: fit-content;
	margin-top: 30px;
}
