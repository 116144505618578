@import '~@app/scss/variables.scss';

.deal-card{
	display: flex;
	background-repeat: no-repeat;
	background-size: cover;
	height: 638px;
	text-decoration: none;
	color: $white;

	&:hover {
		text-decoration: none;
		color: $white;
	}

	&_fullscreen {
		height: 882px;
		background-position: center;
	}

	&_medium {
		position: relative;
		height: fit-content;
		max-width: 450px;
		align-items: flex-end;
		background-size: auto 629px;
		text-align: center;
		color: $white;

		.deal-card {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-end;

			b {
				margin: 5px 0 20px;
			}

			&__description {
				color: $dark-blue-color;
				margin: 10px 0;
			}
		}
	}

	&_large {
		padding-top: 26px;
		padding-bottom: 26px;
		padding-left: 35px;
		justify-content: flex-end;
	}

	&__text-block {
		max-width: 478px;
		display: flex;
		flex-direction: column;
		color: white;
		text-align: center;
		gap: 15px;

		&_medium {
			max-width: 406px;
			padding-bottom: 20px;
		}

		&_large {
			text-align: left;
		}
	}

	@media screen and (max-width: $width-laptop--md) {
		&_medium {
			position: relative;
			max-width: 450px;
			align-items: flex-end;
			background-size: auto 308px;
			margin: 0 20px;

			.deal-card {
				height: 518px;

				&__description {
					margin: 10px 0;
				}
			}
		}
	}

	@media screen and (max-width: $width-tablet--lg) {
		&_fullscreen {
			height: 523px;
			background-position: center;
		}

		&_large {
			height: 360px;
			flex: none !important;
			flex-direction: column;
			padding: 22px 15px 18px;
		}

		&__text-block {
			gap: 10px;
		}
	}

	@media screen and (max-width: $width-mobile--lg) {
		&__text-block {
			max-width: 90%;
		}
	}
}