@import './mixins.scss';

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;

}

.mainItem {
	position: relative;

	& .imageTopPackges {
		background-image: url('../../image/valueinclusive/background-card1.png');
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		@media(max-width: $mobile-width) {}

		& .mainItemCard {
			width: 100%;
			margin-top: 60px;

			@media(max-width: $mobile-width) {
				margin-top: 0px;
			}


			& .mainItemCard-inner {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: space-between;

				@media(min-width:576px) {
					padding: 30px;
				}

				@media(max-width: $mobile-width) {
					justify-content: center;
				}


				& .packages-card {
					width: calc((100% - 120px) / 3);
					cursor: pointer;

					a {
						text-decoration: none;
						color: #000;
					}


					@media(max-width:1024px) {
						margin: 15px;
						width: calc((100% - 90px) / 2);
					}

					@media(max-width: $mobile-width) {
						width: calc((100% - 90px) / 1);
					}
				}
			}

		}

		& .setting-hed {
			font-weight: 400;
			font-style: italic;
			color: #d87544;
			font-family: Playfair Display, serif;
		}

		h1 {

			margin-top: 51px;
			color: $whitecolor;
			font-size: 60px;

			@media(max-width: $mobile-width) {
				font-size: 32px;
				line-height: 35px;
				text-align: center;
				padding: 1px;
			}
		}

		& .btnWarning {
			padding: 15px 50px;
			border-radius: 10px;
			border: none;
			background-color: #d87544;
			color: #fff;
			margin: 0 auto;
			font-size: 18px;
			cursor: pointer;
			display: flex;
			margin-top: 30px;
			margin-bottom: 40px;
		}

		img {
			width: 100%;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
		}
	}
}


.imageCardItem {

	padding: 0 0 15px 0px;
	background-color: $whitecolor;
	position: relative;
	border-radius: 10px;
	box-shadow: 0px 0px 17.899999618530273px 8px #FFFFFF40;

	@media(max-width: 1200px) {
		margin-bottom: 40px;
		box-shadow: 0px 0px 9.8999996185px 7px rgba(0, 0, 0, 0.2509803922);

	}


	@media(max-width: 991px) {
		box-shadow: 0px 0px 9.8999996185px 7px rgba(0, 0, 0, 0.2509803922);
	}

	@media(max-width: $mobile-width) {
		box-shadow: 0px 0px 9.8999996185px 7px rgba(0, 0, 0, 0.2509803922);
	}

	& .cardimage {
		img {
			height: 305px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			@media(max-width:992px){
				height: auto;
			}
		}
	
	}

	& .companyName {
		p {
			font-family: Fira Sans Condensed;
			font-size: 20px;
			font-weight: 500;
			line-height: 24px;
			margin: 0px 16px;
			margin-top: 12px;
		}
	}

	& .logoGiftImage {
		& .logoCard {
			position: absolute;
			right: 3%;
			top: 6%;
			max-width: 150px;
		}

	}



& .batclogo-sec{
	position: relative;
	& .batchlogo {
		img {
			position: absolute;
			top: 65%;
			height: 90px;
			width: 90px;
			right: 3%;

			@media(max-width:1024px){
				position: absolute;
    top: 30%;
    height: 70px;
    width: 70px;
    right: 3%;
			}

			@media (max-width:576px) {
				height: 50px;
				top: 35%;
				right: 5%;
				width: 50px;
			}
		}
	}
	& .logoOceania {
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
		border-radius: 10px;
		background-color: $whitecolor;
		// margin-top: -63px;
		background-color: #fff;
		position: relative;
		margin: 0px 30px 0px 30px;

		img {
			height: 70px;
			width: 39%;
			object-fit: contain;
			margin: 0 auto;
			display: flex;
		}
	}
}


}





.iconCalLocation {
	margin: 15px 16px;
	display: flex;
	justify-content: space-between;

	& .pakeges-icon {
		display: flex;
		align-items: center;

		img {
			height: 24px;
			margin-right: 10px;
		}
	}
}

.vacation-main {
	margin-top: 50px;

	& .personalManager {
		position: relative;
		background-image: url('../../image/valueinclusive/managers.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		height: 350px;
		display: grid;

		& .imageBg {
			img {
				width: 100%;
				margin-top: 20px;
			}
		}

		& .textItem {
			color: $whitecolor;
			margin: auto;
			font-size: 20px;
			line-height: 30px;

			@media(min-width:992px) {
				padding: 30px;
			}

			@media (max-width: 991px) {
				padding: 20px;
			}

			@media(max-width: $mobile-width) {
				padding: 15px;
				text-align: center;
			}

			p {
				@media(max-width:$mobile-width) {
					font-size: 16px;
				}
			}
		}

		& .btnWarning {
			padding: 15px 50px;
			border-radius: 10px;
			border: none;
			background-color: #d87544;
			color: #fff;
			margin: 0 auto;
			font-size: 18px;
			display: flex;
			cursor: pointer;
			margin-top: 30px;
		}
	}

	& .vacation {
		padding: 35px;

		& .managersText {
			color: #d87544;
		}

		h1 {
			font-size: 60px;
			text-align: center;

			@media(min-width: 992px) {
				line-height: 50px;
			}

			@media(max-width: 991px) {
				line-height: 50px;
			}

			@media(max-width:$mobile-width) {
				font-size: 32px;
				line-height: 35px;
			}
		}

		span {
			font-weight: 400;
			font-style: italic;
			color: #d87544;
			font-family: Playfair Display, serif;
		}
	}
}

.testimonail-outer {
	padding: 20px 50px 0 50px;

	@media(max-width: 1200px) {
		padding: 20px;
	}

	@media(max-width: 991px) {
		padding: 20px;
	}

	@media(max-width: $mobile-width) {
		padding: 20px;
	}

	& .slick-dots {
		position: unset;
	}

	& .testimonial-inner {

		& .testimonail-card {
			padding: 10px;
			position: relative;

			& .testimonail-text {
				padding: 50px;
				box-shadow: 0px 4px 4px 0px #00000040;
				border-radius: 10px;
				min-height: 250px;
				max-height: 340px;

				@media(max-width:1200px) {
					padding: 10px;
				}

				@media(max-width: 991px) {
					padding: 20px;
				}

				@media(max-width: $mobile-width) {
					padding: 20px;
				}

				h4 {
					margin-top: 10px;
				}
			}
		}


		button.slick-arrow.slick-next {
			right: 88px;

			@media(max-width:991px) {
				right: 40px;
			}

			@media(max-width: $mobile-width) {
				right: 37px;
			}
		}

		button.slick-arrow.slick-prev {
			left: 70px;

			@media(max-width: 991px) {
				left: 20px;
			}

			@media(max-width: $mobile-width) {
				left: 20px;
			}
		}

		.slick-list {
			padding-top: 20px;
			z-index: -1;
		}
	}

}

.testimonial-hed {
	text-align: center;
	margin: 50px 0;


	& .font-w {
		font-weight: bold;
	}

	@media(max-width: 1200px) {
		margin-top: 60px;
	}

	h1 {
		font-size: 60px;
		display: block;
		margin-bottom: 15px;
		line-height: normal;


		@media(max-width: 991px) {
			line-height: 50px;
		}

		@media(max-width: $mobile-width) {
			font-size: 32px;
			line-height: 35px;
		}
	}

	& .font-style {
		font-weight: 400;
		font-style: italic;
		color: #d87544;
		font-family: Playfair Display, serif;
	}

}

.slick-prev:before,
.slick-next:before {
	font-size: 0 !important;
	background: url("../../image/valueinclusive/arrow-right.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: auto;
	height: 40px;
	width: 40px;
	background-color: $whitecolor;
	box-shadow: 0px 4px 18.100000381469727px 0px #00000040;
	display: block;
	z-index: 9999;
	opacity: 1 !important;
	border-radius: 100%;
}

.slick-prev:before,
.slick-next::after {
	font-size: 0 !important;
	background: url("../../image/valueinclusive/arrow-left.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: auto;
	height: 40px;
	width: 40px;
	background-color: #fff;
	box-shadow: 0px 4px 18.100000381469727px 0px #00000040;
	display: block;
	z-index: 9999;
	opacity: 1 !important;
	border-radius: 100%;
}

.slick-prev {
	left: 10px;
}

.slick-dots {
	li {
		&.slick-active {
			button {
				&:before {
					opacity: .75;
					color: #d87544 !important;
				}
			}
		}
	}
}

.featured-deals {
	background-image: url('../../image/valueinclusive/FeaturedCruiseLines1.jpeg');
	background-repeat: no-repeat;
	position: relative;
	padding: 35px 0;
	background-size: cover;

	@media(max-width:991px) {
		padding-bottom: 50px;
		background-size: cover;
		background-position: center;
	}

	@media(max-width: $mobile-width) {

		padding-bottom: 0px;
	}

	& .featured-outer {
		width: 100%;

		@media(max-width:991px) {
			position: unset;
			transform: unset;
		}

		@media(max-width: $mobile-width) {
			position: unset;
			transform: unset;
		}

		span {
			font-weight: 400;
			font-style: italic;
			color: #d87544;
			font-family: Playfair Display, serif;
		}

		h1 {
			font-size: 60px;
			text-align: center;

			@media(max-width: $mobile-width) {
				font-size: 32px;
				margin-top: 20px;
			}
		}
	}

	&>img {
		width: 100%;


	}

	@media(max-width: 991px) {
		&>img {
			display: none;
		}
	}

	@media(max-width: $mobile-width) {
		&>img {
			display: none;
		}
	}
}

@media(min-width:1680px) {

	.mainItemCard-inner,
	.featured-inner-main {
		max-width: 1380px;
		margin: auto;
	}
}

.featured-inner-main {
	margin-top: 50px;
	display: flex;
	gap: 60px;
	flex-wrap: wrap;

	@media(min-width: 992px) {
		padding: 30px;
	}

	@media(max-width: 991px) {
		margin-top: 40px;
		gap: 30px;
	}

	@media(max-width: 768px) {
		justify-content: center;
	}


	& .featured-inner {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		padding: 0;
		margin-bottom: 40px;
		width: calc(calc(100% - 120px) / 3);
		background-color: $whitecolor;

		@media(max-width: 1024px) {

			margin: 15px;
			width: calc((100% - 120px) / 2);
		}

		@media(max-width: 768px) {

			width: calc((100% - 90px) / 1);
		}

		@media(max-width:576px) {}

		& .featured-card-sec {
			cursor: pointer;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			// border-radius: 10px;
			box-shadow: 0px 0px 9.899999618530273px 7px #00000040;
		}

		& .ocania-sec {
			display: grid;
			place-content: center;
			text-align: center;
			position: relative;
			padding: 30px 10px;
			height: 100px;

			img {
				width: 100%;
			}

			a {
				width: 71%;
				height: 100%;

				img {
					width: 71%;
					height: 100%;

					@media(max-width: 768px) {
						object-fit: contain;
					}
				}
			}

		}

		& .featured-img {
			position: relative;
			height: 270px;

			img {
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
				width: 100%;
				height: 100%;
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;

				@media(max-width:768px) {
					object-fit: cover;
				}
			}
		}

		button {
			border: 0;
			border-radius: 10px;
			font-size: 16px;
			font-weight: 500;
			color: #fff;
			padding: 5px 20px;
			background-color: #d87544;
			position: absolute;
			top: 10px;
			right: 10px;
		}
	}
}

.arrow_box {
	width: 100%;
	position: relative;
	background: #090E2C;
	padding: 5px 5px 5px 10px;

	&>p {
		font-weight: 600;
		margin: 0px;
		color: #ffffff;
		text-align: unset;
	}

	&>p {
		&:nth-child(2) {
			font-size: 14px;
			color: #d87544;
		}
	}
}

.reviewtxt {
	font-size: medium;
	text-align: left;
}


// Top Component

.about-main {

	& .aboutValueInclusive {
		position: relative;
		background-image: url('../../image/valueinclusive/featured.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		display: grid;

		& .aboutImageBg {
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: baseline;
			flex-wrap: wrap;
			// gap: 16px;
			gap: 40px;
			padding: 16px 24px;
			width: fit-content;

			& .inclusive-img {
				text-align: center;
			}

			img {
				width: 130px;
				height: 130px;
				margin-bottom: 16px;


			}
		}

		& .abouttextItem {

			margin: auto;
			font-size: 20px;
			line-height: 30px;
			font-weight: 590;

			span {
				font-weight: 400;
				font-style: italic;
				color: #d87544;
				font-family: Playfair Display, serif;
			}

			@media(min-width:992px) {
				padding: 30px;
			}

			@media (max-width: 991px) {
				padding: 20px;
			}

			@media(max-width: $mobile-width) {
				padding: 15px;
				text-align: center;
			}

			p {
				@media(max-width:$mobile-width) {
					font-size: 16px;
				}
			}
		}

		& .btnWarning {
			padding: 15px 50px;
			border-radius: 10px;
			border: none;
			background-color: #d87544;
			color: #fff;
			margin: 0 auto;
			font-size: 18px;
			display: flex;
			cursor: pointer;
			margin-top: 30px;
		}
	}

	& .aboutUs {
		margin-top: 60px;

		& .aboutText {
			color: #d87544;
		}

		h1 {
			color: black;
			font-size: 60px;
			text-align: center;

			@media(min-width: 992px) {
				line-height: 50px;
			}

			@media(max-width: 991px) {
				line-height: 50px;
			}

			@media(max-width:$mobile-width) {
				font-size: 32px;
				line-height: 35px;
			}
		}

		span {
			font-weight: 400;
			font-style: italic;
			color: #d87544;
			font-family: Playfair Display, serif;
		}
	}
}


// New 

.each-slide-effect>div {
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: cover;
	height: 350px;
}

.each-slide-effect span {
	padding: 20px;
	font-size: 20px;
	background: #efefef;
	text-align: center;
}

.featuredDestination .slick-slide img {
	display: block;
	height: 500px;
	width: 100%;
	object-fit: cover;
}

.featuredDestination.carousel-container__center {
	margin-top: 50px;
}

.carousel-container.featuredDestinationMain {
	padding-bottom: 50px;
}

.featuredDestination .btnfeatured {
	padding: 15px 50px;
	border-radius: 10px;
	border: none;
	background-color: #d87544;
	color: #fff;
	margin: 0 auto;
	font-size: 18px;
	cursor: pointer;
	display: flex;
	margin-top: 30px;
	width: max-content;
}

.carousel-container .slider-container {
	width: calc(100% - 370px);

	@media(max-width:1024px) {
		width: 100%;
	}
}

.slick-slider,
.slick-list,
.slick-track {
	padding: 0;
}

.trustpilot-widget {
	height: 150px;

	@media(max-width: 576px) {
		height: 250px;

	}
}