@import '~@app/scss/variables.scss';

div#root .rc-tabs {
	border: none;

	.rc-tabs-nav-measure-ping-right::after,
	.rc-tabs-nav-wrap-ping-right::after,
	.rc-tabs-nav-measure-ping-left::before,
	.rc-tabs-nav-wrap-ping-left::before {
		width: 32px;
		border: none;
	}

	.rc-tabs-nav-wrap-ping-left::before {
		box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
	}

	.rc-tabs-nav-wrap-ping-right::after {
		box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
	}

	.rc-tabs-tab {
		margin-right: 20px;
		background: transparent;
	}

	.rc-tabs-nav-operations {
		display: flex;
		height: 100%;
		justify-content: center;
		align-items: center;
	}

	.rc-tabs-nav-more {
		background: transparent;
		border: none;
		height: fit-content;
		cursor: pointer;

		svg {
			width: 14px;
			height: 14px;
		}
	}
}

.rc-tabs-dropdown {
	border: none;
	border-radius: 8px;
	box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
	0 3px 6px -4px rgba(0, 0, 0, 0.12),
	0 9px 28px 8px rgba(0, 0, 0, 0.05);
	overflow-x: hidden;
	overflow-y: auto;
	font-family: "Fira Sans", sans-serif;
	z-index: 2;

	.rc-tabs-dropdown-menu {
		max-height: 200px;
		margin: 0;
		padding: 4px 0;
		text-align: left;
		list-style-type: none;
		background-color: $white;
		background-clip: padding-box;
		outline: none;
	}

	.rc-tabs-dropdown-menu-item {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		display: flex;
		align-items: center;
		min-width: 120px;
		margin: 0;
		padding: 4px 12px;
		color: rgba(0, 0, 0, 0.88);
		font-weight: normal;
		font-size: 14px;
		line-height: 1.5714285714285714;
		cursor: pointer;
		transition: all 0.3s;

		&:hover {
			background: rgba(0, 0, 0, 0.04);
		}
	}
}
