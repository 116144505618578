@import '@app/scss/variables.scss';

.badge {
  align-items: center;
  gap: 5px;

	&__count {
    position: absolute;
    right: 6px;
    top: 3px;

    display: inline-block;

    width: 18px;
		min-width: 18px;
    height: 18px;
    color: $white;
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
    white-space: nowrap;
    text-align: center;
    background: $brand-dark-orange;
    border-radius: 10px;
    box-shadow: 0 0 0 1px $white;
	}
}