@import '~@app/scss/variables.scss';
@import '~@app/scss/components/table.scss';
@import '~@app/scss/components/tabs.scss';

html, body, h1, h2, h3, h4, h5, h6, p {
	margin: 0;
	padding: 0;
}


body,
#root {
	min-height: 100vh;
}

#chat-widget-container {
	z-index: 3 !important;

	@media screen and (max-width: $width-tablet) {
		bottom: 25px !important;
		right: 16px !important;
	}

	@media screen and (max-width: $width-mobile--lg) {
		bottom: 23px !important;
		right: 16px !important;
	}
}

.root-container {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
}

.is-relative {
	position: relative;
}

.form-group {
	margin-bottom: 15px;

	&_large {
		margin-bottom: 45px;
	}

	&_outlined {
		border: 1px solid $blue-color;
		padding: 20px;
		border-radius: 15px;
		position: relative;

		& .text-field-container__component__input-group__placeholder_active {
			top: -15px;
		}

		& .select-component__control-block__placeholder_top {
			transform: translateY(-14px);
		}

		&--error {
			border: 1px solid red;
			background-color: rgba(255, 0, 47, 0.13);
			color: red;
		}
	}

	&:hover {
		.form-group__btn-close {
			display: block;
		}
	}

	&__btn-close {
		background-color: $white;
		border: none;
		top: -24px;
		right: -28px;
		display: none;

		svg {
			fill: red !important;
		}
	}

	&__title {
		position: absolute;
		top: 0;
		left: 15px;
		transform: translateY(-50%);
		background-color: white;
		padding-inline: 5px;
	}
}

.single-button {
	justify-content: flex-end;
}

.icon-margin {
	margin-left: 10px;
}

a {
	transition: all .5s linear;
	cursor: pointer;

	svg {
		transition: all .5s linear;
		color: $dark-blue-color;

		&:hover {
			color: $brand-dark-orange;
		}
	}

	&:hover {
		color: $yellow-color;
		text-decoration: underline;
	}
}

.no-scroll-body {
	overflow: hidden;
}

.no-padding {
	padding: 0 !important;
}

.grecaptcha-badge {
	visibility: hidden;
}

.disabled {
	pointer-events: none;
}