@import '@app/scss/variables.scss';

.card-deals {
	width: 337px;
	transition: all .5s ease;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
	margin: 0 30px;
	background: $white;

	&__logo {
		max-width: 100px;
		max-height: 50px;
	}

	&__top-block {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background-repeat: no-repeat;
		background-size: cover;
		height: 360px;
		padding: 18px 19px 15px 17px;
		position: relative;
	}

	&__title {
		text-transform: uppercase;
		margin: 0;
	}

	&__header-block,
	&__footer-block {
		display: flex;
		justify-content: space-between;
	}

	&__header-block {
		align-items: center;
	}

	&__footer-block {
		color: white;
		flex-direction: column;
	}

	&__location-block {
		display: flex;
		align-items: center;

		&__text {
			margin: 0;
			margin-top: 8px;
		}
	}

	&__hover-arrow {
		position: absolute;
		visibility: hidden;
		opacity: 0;
		bottom: 19px;
		right: 16px;
		width: 31px;

		transition: all .5s linear;
 	}

	&__bottom-block {
		background-color: white;
		height: 139px;
		padding: 25px 0 0 15px;
	}

	&__description {
		display: flex;
		flex-direction: column;
		gap: 5px;
		margin: 0;
		padding-left: 26px;
	}

	&:hover {
		cursor: pointer;
		transform: scale(1.07);

		.card-deals__hover-arrow {
			visibility: visible;
			opacity: 1;
		}
	}

	@media screen and (max-width: $width-tablet--lg) {
		margin: 0 10px;
	}

	@media screen and (max-width: $width-tablet) {
		width: 290px;

		&__top-block {
			height: 292px;
		}
	}

}

// skeleton
.card-deals.skeleton {
	.card-deals {
		&__logo,
		&__description,
		&__tag {
			background: $skeleton-background;
			animation: $skeleton-animation;
			background-size: 400% 100%;
			height: 16px;
		}

		&__tag {
			width: 70px;
		}

		&__logo {
			width: 60px;
			height: 60px;
			border-radius: 50%;
		}

		&__description {
			width: 200px;
			padding: 0;
			position: relative;

			&::after,
			&::before {
				content: '';
				height: 16px;
				position: absolute;
				background: $skeleton-background;
				animation: $skeleton-animation;
				background-size: 400% 100%;
			}

			&::after {
				width: 220px;
				top: 30px;
				left: 0;
			}

			&::before {
				width: 240px;
				top: 60px;
				left: 0;
			}
		}
	}
}
