@import '~@app/scss/variables.scss';

$marginSize: 10px;

.form {
	margin: $marginSize * 3 auto;

	form {
		margin-top: $marginSize * 3;
	}

	&__checkbox-block {
		margin-top: $marginSize * 2;
	}

	&__title {
		margin: 0 auto $marginSize * 2;
		text-align: center;
	}

	&__subtitle {
		text-align: center;
	}

	&__btn-submit {
		margin: $marginSize * 2 0 0;
	}

	&-margin {
		margin: 0 auto;
	}

	&__add-group-container {
		display: flex;
		margin-bottom: $marginSize * 2;
	}

	&__add-group-brn {
		margin-left: auto;
		padding-right: 0;;
	}

	&__extra-contact {
		width: 100%;
		margin-top: $marginSize * 2;

		h3 {
			margin-bottom: $marginSize;
		}

		&__text {
			margin-top: $marginSize;
		}
	}

	&__email-form {
		form {
			display: flex;
			flex-direction: column;
			height: calc(100% - 92px);
			justify-content: space-between;
		}

		&_container {
			box-shadow: 0 4px 25px rgb(0 0 0 / 10%);
			border-radius: 5px;
			height: 100%;
			position: relative;
			transition: all .5s ease;

			&:hover {
				cursor: pointer;
				box-shadow: 0 4px 25px rgba(0, 0, 0, 0.261);
			}
		}
	}

	@media screen and (max-width: $width-tablet) {
		&__add-group-container {
			box-shadow: 0 4px 25px rgb(0 0 0 / 10%);
			border-radius: 5px;
			margin-bottom: $marginSize * 2;
			padding-top: $marginSize;
		}

		&__extra-contact {
			&__text {
				margin-left: -25px;
			}
		}
	}
}