@import '~@app/scss/variables.scss';

$mobile-action-color: #0978BD;

.search-page {
	&__container {
		flex-direction: row !important;
		padding-top: 43px !important;
		padding-bottom: 76px !important;
	}

	&__btn-filters {
		width: fit-content;
		color: $mobile-action-color;

		button {
			background: transparent;
			color: $mobile-action-color;
			border: none;
			cursor: pointer;

			svg {
				width: 17px;
				height: 14px;

				path {
					fill: $mobile-action-color;
				}

				@media screen and (max-width: 768px) {
					height: 10px;
				}
			}

			&:hover {
				color: #31a0e5;

				svg {
					path {
						fill: #31a0e5;
					}
				}
			}
		}

		display: none;
		pointer-events: none;

		@media screen and (max-width: $width-tablet--lg) {
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			gap: 15px;
			pointer-events: all;
		}
	}
}