@import '~@app/scss/variables.scss';

.feedback {
	flex-direction: column;
	height: 650px;
	overflow: hidden;

	&__container {
		flex-direction: column;
		width: 100%;
	}

	&__title-block {
		flex-direction: column;
		text-align: center;
	}

	&__quote {
		font-size: 128px;
		line-height: 110%;
		color: $brand-dark-orange;
	}

	@media screen and (max-width: $width-tablet--lg) {
		&__title-block {
			width: 90%;
			margin: 0 auto;
		}
	}

	@media screen and (max-width: $width-tablet) {
		height: 430px;
	
		&__title {
			margin-bottom: 16px;
		}

		&__quote {
			font-size: 70px;
			line-height: 50%;
		}
	}
}

.feedback-card {
	width: 	553px;
	height: 341px;
	flex-direction: column;
	background: $white;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
	margin: 0 20px;

	&__author {
		text-transform: uppercase;
	}

	&__container {
		flex-direction: column;
		justify-content: center;
		text-align: center;
		color: $dark-blue-color;
	}

	&__date {
		color: $dark-grey-color;
	}

	&__description {
		margin: 18px 0;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 9;
	}

	@media screen and (max-width: 930px) {
		width: 290px;
	}
}

.feedback-card.feedback-skeleton {
	.feedback-card {
		&__date,
		&__description,
		&__author {
			height: 16px;
			background: $skeleton-background;
			background-size: 400% 100%;
			animation: $skeleton-animation;
		}

		&__date {
			width: 57px;
		}

		&__description {
			width: 200px;
		}

		&__author {
			width: 120px;
		}
	}
}

.feedback-carousel {
	width: 100%;
}