@import '~@app/scss/variables.scss';

b {
	font-style: normal;
	text-transform: uppercase;
}

i {
	font-style: italic;
}

.font-family {
	/* Font Families */
	&_primary {
		font-family: 'Fira Sans', sans-serif;

		&.condensed {
			font-family: 'Fira Sans Condensed', sans-serif;
		}
	}

	&_accent {
		font-family: 'Saira', sans-serif;

		&.condensed {
			font-family: 'Saira Sans', sans-serif;
		}
	}

	&_decorative {
		font-family: 'Playfair Display', serif;
	}
}

/* Font Weights */
.font-weight {
	&_light {
		font-weight: 300;
	}

	&_regular {
		font-weight: 400;
	}

	&_medium {
		font-weight: 500;
	}

	&_semi-bold {
		font-weight: 600;
	}

	&_bold {
		font-weight: 700;
	}

	&_extra-bold {
		font-weight: 800;
	}
}

/* Text Sizes */
.text-size {
	&_xx-small {
		font-size: 12px;
		line-height: 16px;
	}

	&_extra-small {
		font-size: 14px;
		line-height: 16px;
	}

	&_small {
		font-size: 16px;
		line-height: 18px;
	}

	&_normal {
		font-size: 18px;
		line-height: 22px;
	}

	&_medium {
		font-size: 20px;
		line-height: 24px;
	}

	&_large {
		font-size: 24px;
		line-height: 26.5px;
	}

	&_soft-large {
		font-size: 36px;
		line-height: 43px;
	}

	&_hard-large {
		font-size: 60px;
		line-height: 110%;
	}

	&_extra-large {
		font-size: 84px;
		line-height: 101px;
	}

	@media screen and (max-width: $width-laptop--sm) {
		&_extra-large {
			font-size: 64px;
			line-height: 110%;
		}
	}

	@media screen and (max-width: $width-tablet) {
		&_normal {
			font-size: 14px;
			line-height: 16px;
		}

		&_medium {
			font-size: 14px;
			line-height: 21px;
		}

		&_large {
			font-size: 16px;
			line-height: 18px;
		}

		&_hard-large {
			font-size: 36px;
			line-height: 110%;
		}

		&_extra-large {
			font-size: 40px;
			line-height: 120%;
		}
	}

	@media screen and (max-width: $width-mobile--lg) {
		&_normal {
			font-size: 12px;
			line-height: 14px;
		}

		&_extra-large {
			font-size: 36px;
			line-height: 120%;
		}
	}
}

.line-height {
	&_medium {
		line-height: 110%;
	}

	&_large {
		line-height: 150%;
	}

	&_extra-large {
		line-height: 200%;
	}
}
