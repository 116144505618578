@import '~@app/scss/variables.scss';

$middle-section-height: 1266px;

.bottom-section {
	display: flex;
	width: 100%;

	.deal-card {
		width: 50%;
		box-sizing: border-box;
	}

	@media screen and (max-width: $width-tablet--lg) {
		flex-direction: column;

		.deal-card {
			width: 100%;
		}
	}
}

.middle-section {
	position: relative;
	display: flex;
	justify-content: center;
	height: fit-content;

	&__background-block {
		position: absolute;
		top: 0;
		height: 531px;
		width: 100%;
		background: -webkit-linear-gradient(90deg, #ffffff,#e0f7ff);                  
		background: linear-gradient(90deg, #ffffff,#e0f7ff);
		opacity: 0.8;
		z-index: -1;
	}

	&__title-block {
		position: absolute;
		bottom: 0;
		right: 0;
		left: calc(50% - 150px); 
		top: 120px;
		max-width: 300px;
		height: fit-content;
		text-align: center;
	}

	&__title {
		margin-bottom: 17px;
		text-align: center;
	}

	&-carousel {
		display: none;
	}

	.slider-container {
		display: none;
	}


	@media screen and (max-width: $width-laptop--md) {
		height: fit-content;
		overflow: hidden;
		padding: 65px 0;
		flex-direction: column;
		align-items: center;
		gap: 20px;

		&__background-block {
			height: 351px;
		}

		&__title-block {
			position: unset;
			max-width: 300px;
			height: fit-content;
		}

		&-carousel {
			display: block;
			margin-bottom: 30px;
		}

		.slider-container {
			display: block;
			width: 100%;
			margin: 0 10px;
		}

		.carousel__pagination {
			margin-top: 0;
		}
		
	}

	@media screen and (max-width: $width-mobile--lg) {
		.deal-card {
			width: 290px;
		}

		&__title-block {
			button {
				width: 90%;
				margin: 0 auto;
			}
		}
	}
}

.deal-card-container {
	margin: 195px 0 20px;
	display: flex;
	justify-content: center;
	gap: 39px;

	&__item:nth-of-type(2) {
		margin: 180px 0 20px;
	}

	&__item:nth-of-type(3) {
		.deal-card {
			background-position: center;
		}
	}

	@media screen and (max-width: $width-laptop--md) {
		display: none;

		.deal-card {
			min-width: 100%;
			margin: 0 5vw;
		}
	}
}