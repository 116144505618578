@use 'sass:math';
@import '~@app/scss/variables.scss';

$grid-columns: 12;

$grid-vertical-gap: 20px;
$grid-horizontal-gap: 30px;

.row {
	display: flex;
	flex-direction: row;
	gap: $grid-horizontal-gap;
	width: 100%;
	margin: 0 -15px;
}

.layout-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: math.div($grid-vertical-gap, -2) math.div($grid-horizontal-gap, -2);

	&.dense {
		margin: 0;
	}

	&.reverse {
		flex-direction: row-reverse;
	}
}

@mixin column($span) {
	display: flex;
	flex-direction: column;

	padding: math.div($grid-vertical-gap, 2) math.div($grid-horizontal-gap, 2);
	box-sizing: border-box;

	flex: $span 1 math.div($span, $grid-columns) * 100%;
	max-width: math.div($span, $grid-columns) * 100%;
}

.col {
	@include column($grid-columns);

	$breakpoints: (
			"-": 0,
			"-xs-": $breakpoint-xs,
			"-sm-": $breakpoint-sm,
			"-md-": $breakpoint-md,
			"-lg-": $breakpoint-lg,
			"-xl-": $breakpoint-xl
	);
	@each $name, $value in $breakpoints {
		@media (min-width: $value) {
			@for $i from 1 through $grid-columns {
				&#{$name}#{$i} {
					@include column($i);

					@at-root .layout-container.dense > & {
						padding: 0;
					}
				}
			}
		}
	}
}

.content {
	&-halign {
		&_left {
			display: flex;
			align-items: flex-start;
		}

		&_center {
			display: flex;
			align-items: center;
		}

		&_right {
			display: flex;
			align-items: flex-end;
		}
	}

	&-valign {
		&_top {
			display: flex;
			justify-content: flex-start;
		}

		&_center {
			display: flex;
			justify-content: center;
		}

		&_bottom {
			display: flex;
			justify-content: flex-end;
		}
	}
}
